<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <v-text-field
              v-model="form.stock"
              label="Stock"
              hint="Total quantity of this slot"
              suffix="ppl"
              :rules="[between(form.stock, 0, 99999999)]"
            ></v-text-field>

            <v-text-field
              v-model="form.booking_ends_before_minutes"
              label="Booking Ends before"
              hint="e.g. 1) By setting 60 minutes, the slot booking is disabled at 10:00am given the slot starts at 11:00am. 2) If you set 10080 minutes, booking is disabled 7 days before the start time."
              suffix="minutes"
              :rules="[between(form.sort, 1, 99999999)]"
            ></v-text-field>

            <v-text-field
              v-model="form.duration_minutes"
              label="Duration"
              hint="Slot session duration duration=60m, it generates: 6:30 (start) <-Duration=60m-> end 7:30 (end)"
              suffix="minutes"
              :rules="[between(form.sort, 1, 99999999)]"
            ></v-text-field>

            <v-text-field
              v-model="form.gap_after_minutes"
              label="Slot Gap after"
              hint="Slots would be 6:00-7:30 <-Gap=60m-> 8:30-10:00"
              suffix="minutes"
              :rules="[between(form.sort, 0, 99999999)]"
            ></v-text-field>

            <!-- <v-text-field
              v-show="false"
              v-model="form.start_from_hour"
              label="Service Start Hour"
              suffix="hour"
              :rules="[between(form.start_from_hour, 0, 24)]"
            ></v-text-field> -->

            <p>Service Start Time</p>
            <v-time-picker
              v-model="temp.start"
              class="mb-5"
            ></v-time-picker>

            <!-- <v-text-field
              v-model="form.end_to_hour"
              label="Service End Hour"
              suffix="hour"
              :rules="[between(form.end_to_hour, 0, 24)]"
            ></v-text-field> -->

            <p>Service End Time</p>
            <v-time-picker
              v-model="temp.end"
              class="mb-5"
            ></v-time-picker>
            <!--
            <v-text-field
              v-model="form.days_in_week"
              label="Applied Days in Week (Mon - Sun)"
              :rules="[required]"
            ></v-text-field> -->

            <v-select
              v-model="selectedDays"
              label="Applied Days in Week (Mon - Sun)"
              :items="daysOptions"
              clearable
              item-text="title"
              item-value="value"
              multiple
              outlined
            ></v-select>

            <v-select
              v-model="form.price_group_id"
              label="Price Group"
              :items="priceGroupOptions"
              clearable
              item-text="title"
              item-value="value"
              outlined
            ></v-select>

            <v-autocomplete
              v-model="form.time_zone_id"
              label="Time Zone"
              :items="timeZoneOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              :rules="[between(form.time_zone_id, 1, 999)]"
            ></v-autocomplete>

            <v-text-field
              v-model="form.sort"
              label="Sort"
              hint="Smaller sort gets generated first. If latter rules try to generate overalpping time range, they are ignored."
              suffix="piority"
              :rules="[between(form.sort, 0, 99999999)]"
            ></v-text-field>

            <v-text-field
              v-model="form.auto_days"
              label="Daily Auto Generate the N-th days"
              hint="For 60D, today auto generates slots on 2 May (2 Mar + 60D = approx 2 May)."
              :rules="[between(form.auto_days, 1, 9999)]"
            ></v-text-field>

            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
              :message="[ `Active rule will be applied to generate slots automatically to the ${form.auto_days}-th day` ]"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { hourToString } from '@/@core/utils/filter'
import appStore from '@/modules/app/store'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import usePriceForm from '../price-resource/usePriceForm'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    sku: {
      // default resource
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      stock: props.resource ? props.resource.stock : 100,
      sort: props.resource ? props.resource.sort : 10,
      booking_ends_before_minutes: props.resource ? props.resource.booking_ends_before_minutes : 60,
      duration_minutes: props.resource ? props.resource.duration_minutes : 120,
      gap_after_minutes: props.resource ? props.resource.gap_after_minutes : 0,

      // start_from_hour: props.resource ? props.resource.start_from_hour : 6,
      // end_to_hour: props.resource ? props.resource.end_to_hour : 18,
      days_in_week: props.resource ? props.resource.days_in_week : '1,1,1,1,1,1,1',
      price_group_id: props.resource ? props.resource.price_group_id : null,
      time_zone_id: props.resource ? props.resource.time_zone_id : null,
      auto_days: props.resource ? props.resource.auto_days : null,
      active: props.resource ? props.resource.active : true,
    }

    const form = ref({ ...initialForm })

    const temp = ref({
      start: hourToString(props.resource?.start_from_hour || 11),
      end: hourToString(props.resource?.end_to_hour || 22),
    })

    const formElem = ref(null)

    const validate = () => {
      const start = temp.value.start.split(':').map(v => parseFloat(v) || 0)
      const end = temp.value.end.split(':').map(v => parseFloat(v) || 0)
      console.log('start end', start, end)
      form.value.start_from_hour = (start[0] + start[1] / 60).toFixed(3)
      form.value.end_to_hour = (end[0] + end[1] / 60).toFixed(3)
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const { priceGroups, loadPriceGroups } = usePriceForm()

    onMounted(() => {
      loadPriceGroups(props.sku)
      const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (!form.value.time_zone_id) {
        const autoTz = appStore.state.timeZones.find(tz => tz.name === tzName) || false
        form.value.time_zone_id = autoTz.id
      }
    })

    const priceGroupOptions = computed(() => priceGroups.value.map(group => ({ title: group.name.en, value: group.id })))

    const timeZoneOptions = computed(() => appStore.state.timeZones.map(tz => ({ title: tz.name, value: tz.id })))

    const daysOptions = [
      { title: 'Mon', value: 1 },
      { title: 'Tue', value: 2 },
      { title: 'Wed', value: 3 },
      { title: 'Thu', value: 4 },
      { title: 'Fri', value: 5 },
      { title: 'Sat', value: 6 },
      { title: 'Sun', value: 7 },
    ]

    const selectedDays = ref(form.value.days_in_week.split(',').map((val, index) => (val === '1' ? index + 1 : 0)))

    watch([selectedDays], () => {
      console.log('selected days', selectedDays.value)
      const days = []
      for (let d = 1; d <= 7; d++) {
        if (selectedDays.value.indexOf(d) >= 0) {
          days.push('1')
        } else {
          days.push('0')
        }
      }
      form.value.days_in_week = days.toString()
      console.log('selected days', selectedDays.value, form.value.days_in_week)
    })

    return {
      form,
      formElem,
      temp,
      validate,
      between,
      required,

      selectedDays,
      daysOptions,
      priceGroupOptions,
      timeZoneOptions,
    }
  },
}
</script>
