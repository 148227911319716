<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-autocomplete
          v-model="form.message_ids"
          label="Message to attach"
          item-text="label"
          item-value="value"
          outlined
          :items="distributorMessageOptions"
          multiple
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import useDistributorMessageList from '@/modules/distributor/views/distributor-message/useDistributorMessageList';
import { required } from '@core/utils/validation';
import { } from '@mdi/js';
import { computed, ref } from '@vue/composition-api';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'product',
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    ignoreMessageIds: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    // const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      message_ids: [],
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const { distributorMessageOptions, loadDistributorMessages } = useDistributorMessageList()

    const { position } = props
    loadDistributorMessages({ take: 999, position })

    return {
      form,
      formElem,
      validate,
      required,

      distributorMessageOptions: computed(() => distributorMessageOptions.value.filter(o => !props.ignoreMessageIds.includes(o.value))),
    }
  },
}
</script>
