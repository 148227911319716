<template>
  <v-row>
    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>{{ t(product.title) }}</span>
          <div>
            <v-chip
              small
              color="primary"
              :class="`v-chip-light-bg text-sm font-weight-semibold text-capitalize ml-2 primary--text`"
            >
              {{ product.nature }}
            </v-chip>
            <v-chip
              small
              :color="product.status === 'published' ? 'success' : ''"
              :class="{ 'success--text': product.status === 'published' } "
              class="v-chip-light-bg text-sm font-weight-semibold ml-2 text-capitalize"
            >
              {{ product.status }}
            </v-chip>
            <v-chip
              v-if="product.status !== 'unpublished'"
              small
              class="v-chip-light-bg text-sm font-weight-semibold ml-2 text-capitalize"
            >
              {{ product.active ? 'Active' : 'Inactive' }}
            </v-chip>
            <v-icon
              small
              class="ml-2"
            >
              {{ product.visible ? icons.mdiEye : icons.mdiEyeRemove }}
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            <b>Product slug:</b>
            {{ product.slug }}
          </p>
          <p>
            <b>Product Number:</b>
            {{ product.product_number }}
          </p>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-text>
          <h3>Content</h3>
          <p>Main Product description shown in the detail page.</p>
          <div
            class="mb-4 html-content"
            v-html="t(product.content)"
          >
          </div>

          <h3>Distributor Message</h3>
          <p>Certain Distributor users can see additional customized information pieces. (e.g. use as promotional message.)</p>

          <manage-messages-list
            :messages="product.messages"
            position="product"
            path="products"
            :attach-ids="[product.id]"
            :detach-ids="[product.id]"
            @updated="$emit('updated')"
          />
        </v-card-text>
      </v-card>

      <v-card
        v-if="product.nature_info && product.nature_info.service"
        class="mt-2"
      >
        <v-card-title>
          Nature Info
        </v-card-title>
        <v-card-text>
          <div>
            <div>Participants: {{ product.nature_info.service.min_participant }} - {{ product.nature_info.service.max_participant }} ppl</div>
            <div>Duration: {{ product.nature_info.service.min_duration_minute }} - {{ product.nature_info.service.max_duration_minute }} mins</div>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Stats
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="product.stats"
          >
            <v-col cols="4">
              {{ product.stats.rating }}
              <v-icon
                size="20"
                color="warning"
              >
                {{ icons.mdiStar }}
              </v-icon>
            </v-col>
            <v-col cols="4">
              <router-link
                :to="{ name: 'product-review-list', query: { product_id: product.id } }"
              >
                {{ product.stats.no_comment }}
              </router-link>
              <v-icon
                size="20"
                color="info"
              >
                {{ icons.mdiComment }}
              </v-icon>
            </v-col>
            <v-col cols="4">
              {{ product.stats.no_purchase }}
              <v-icon
                size="20"
                color="warning"
              >
                {{ icons.mdiShopping }}
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Media
        </v-card-title>
        <v-card-text>
          <div v-if="!product.media.length">
            No Media uploaded. Please upload from GALLERY.
          </div>
          <div>
            <v-avatar
              v-for="media in product.media"
              :key="media.id"
              size="120"
              rounded
            >
              <v-img
                :src="media.instance.thumbnail"
              ></v-img>
            </v-avatar>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          SKU overview
        </v-card-title>
        <v-card-text>
          <div
            v-for="sku in product.skus"
            :key="sku.id"
          >
            <span
              v-if="sku.snapshot.product_title.en !== product.title.en"
              class="red"
            >
              {{ t(sku.snapshot.product_title) }} -
            </span>
            <b :class="{ red: sku.snapshot.sku_name.en !== sku.name.en }">
              {{ t(sku.snapshot.sku_name) }},
            </b>
            <span>
              Price range:
              {{ product.reference_price.min_sku_price | localPrice({ currency: product.currency }) }} -
              {{ product.reference_price.max_sku_price | localPrice({ currency: product.currency }) }}
            </span>
          </div>

          <h3 class="mt-4">
            Package
          </h3>

          <p>
            The product is <b v-if="!product.has_package">NOT</b> bought
            <a @click="$emit('tab', 'SKUS')">as a Package</a>
          </p>

          <h3 class="mt-4">
            Country, Currency, Tax Scheme
          </h3>

          <p v-if="product.country">
            Based: {{ t(product.country.name) }}
          </p>

          <p v-if="product.currency">
            Currency: {{ product.currency }}
          </p>

          <p v-if="product.tax_scheme">
            {{ t(product.tax_scheme.name) }}
            <b>({{ product.tax_scheme.percentage }}%)</b>
          </p>

          <p v-if="product.time_zone">
            {{ product.time_zone.name }}
          </p>

          <!-- <v-alert
            v-else
            color="warning"
          >
            Business might require TAX but this product has no tax scheme.
          </v-alert> -->
        </v-card-text>
      </v-card>

      <div class="text-center mt-5">
        <v-btn
          color="secondary"
          class="me-3"
          large
          @click="$router.push({ name: 'product-update-resource', query: { product_id: product.id } })"
        >
          Edit
        </v-btn>

        <v-btn
          v-if="isPublishable(product)"
          color="primary"
          class="me-3"
          large
          @click="publishProduct(product.id).then(() => { $emit('updated'); useNotifySuccess({ content: 'Product Published.' }) })"
        >
          Publish
        </v-btn>

        <v-btn
          v-if="isUnpublishable(product)"
          color="error"
          class="me-3"
          @click="unpublishProduct(product.id).then(() => $emit('updated'))"
        >
          Unpublish
        </v-btn>
      </div>

      <div class="text-center mt-5">
        <a
          :href="preViewUrl"
          target="_blank"
          class="text-decoration-none text--secondary"
        >
          Preview This Product
        </a>

        <a
          v-if="isPublishable(product)"
          class="text-decoration-none ml-4"
          @click="aside = 'publish.product'"
        >
          Schedule Publish
        </a>

        <a
          v-if="isUnpublishable(product)"
          class="text-decoration-none ml-4"
          @click="aside = 'unpublish.product'"
        >
          Schedule Unpublish
        </a>
      </div>
    </v-col>

    <v-col cols="4">
      <v-card class="pt-5">
        <v-card-title class="justify-center flex-column">
          {{ t(product.merchant.name) }}
        </v-card-title>
        <v-card-text v-show="product.nature === 'tangible'">
          <div>shipping fee: {{ product.merchant.shipping_rule.shipping_fee | localPrice({ currency: product.currency }) }}</div>
          <div>waived over subtotal: {{ product.merchant.shipping_rule.waived_at_subtotal | localPrice({ currency: product.currency }) }}</div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <router-link
            class="me-3 text-decoration-none"
            :to="{ name: 'merchant-overview', params: { id: product.merchant.id }, query: { tab: product.nature === 'tangible' ? 'SHIPPING FEE' : '' } }"
          >
            Edit
          </router-link>
        </v-card-actions>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center">
          Policy
        </v-card-title>
        <v-card-text>
          <div
            class="mb-4 short-content"
            v-html="t(product.policy.cancellation_text)"
          >
          </div>
          ...
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center">
          Order Remark
        </v-card-title>
        <v-card-text>
          <template v-if="product.order_remark">
            <h2 class="text-xl font-weight-semibold mb-2">
            </h2>
            <div
              class="mb-4 short-content"
              v-html="t(product.order_remark.content)"
            >
            </div>
            ...
          </template>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center">
          Participant Input Rule
        </v-card-title>
        <v-card-text>
          <div
            v-show="!product.participant_rule.mandatory"
          >
            <b>Participant Input is NOT mandatory</b>
          </div>
          <div
            v-for="(value, attr) in product.participant_rule.fields"
            :key="attr"
            class="d-flex justify-space-between"
            :class="{ 'text--primary': value === 'required' }"
          >
            <template v-if="['optional', 'required'].includes(value)">
              <span>{{ attr }}</span>
              <span>{{ value }}</span>
            </template>
          </div>
          <v-divider class="mt-2" />
          <div class="mt-2">
            PWC is requiring their newer APP to supply more data. Normally you just need to require "all service" and "some voucher" products to mandate participant input. If some voucher mandates participants but their old APP provides none, a template email can be sent out (due to no participants).
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center flex-column">
          Datetimes
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <span>Published At: </span>
            <span v-if="product.published_at">{{ product.published_at | date }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Created At: </span>
            {{ product.created_at | date }}
          </div>
          <div class="d-flex justify-space-between">
            <span>Updated At: </span>
            {{ product.updated_at | date }}
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center flex-column">
          Scheduled Actions
        </v-card-title>
        <v-card-text>
          <model-schedule-list-table
            :key="aside"
            :default-query="{
              schedulable_id: product.id,
              schedulable_type: 'product',
            }"
            :hide-default-footer="true"
          />

          <model-schedule-aside
            v-if="['publish.product', 'unpublish.product'].includes(aside)"
            :active="true"
            :action="aside"
            :schedulable-id="product.id"
            schedulable-type="product"
            @changed="aside = `updated_schedule_${Math.random(10)}`"
            @aside="handleAside"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { t } from '@/plugins/i18n'

import { useNotifySuccess } from '@/composables'
import ModelScheduleAside from '@/modules/common/views/model-schedule/ModelScheduleAside.vue'
import ModelScheduleListTable from '@/modules/common/views/model-schedule/ModelScheduleListTable.vue'
import ManageMessagesList from '@/modules/distributor/views/distributor-message/ManageMessagesList.vue'
import { date, localPrice } from '@core/utils/filter'
import {
mdiComment,
mdiEye, mdiEyeRemove,
mdiShopping,
mdiStar,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useProduct from '../../useProduct'

export default {
  components: { ManageMessagesList, ModelScheduleListTable, ModelScheduleAside },

  filters: { localPrice, date },

  props: {
    product: { type: Object, required: true },
  },

  setup(props, { emit }) {
    const {
      publishProduct, unpublishProduct, isPublishable, isUnpublishable,
    } = useProduct()
    const previewUrl = process.env.VUE_APP_PRODUCT_PREVIEW_URL
    const aside = ref('')

    const handleAside = bol => {
      if (!bol) {
        aside.value = ''
      }
    }

    return {
      aside,
      handleAside,

      isPublishable,
      isUnpublishable,
      publishProduct,
      unpublishProduct,
      useNotifySuccess,
      t,
      preViewUrl: `${previewUrl}/products/${props.product.slug}`,

      icons: {
        mdiStar,
        mdiComment,
        mdiShopping,
        mdiEye,
        mdiEyeRemove,
      },
    }
  },
}
</script>

<style lang="scss">
.short-content {
  max-height: 100px;
  overflow: hidden;
}
.html-content {
  img {
    max-width: 100%;
  }
}
.red {
  color: red;
}
</style>
