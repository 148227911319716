<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <price-form
      :resource="resource"
      :product="product"
      :loading="loading"
      :errors="errors"
      :cols="12"
      @submit="handleSubmit"
    />

    <div
      v-if="resource && resource.computed_cost > 0"
      class="text-center mt-4 mb-4"
    >
      <div class="mb-2">
        Having "0.00" cost in reporting?
      </div>
      <a @click="confirmSetItemCost">Fix it to {{ resource.computed_cost }}</a>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useConfirm, useExtractErrors, useNotifySuccess } from '@/composables'
import { setItemCost, storePrice, updatePrice } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PriceForm from './PriceForm.vue'

export default {
  components: { PriceForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    priceGroup: {
      type: [Object, Boolean],
      default: false,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form }
      if (!props.resource) {
        data.price_group_id = props.priceGroup.id
      }
      const request = props.resource ? updatePrice(props.resource.id, data) : storePrice(data)
      await request
        .then(res => {
          emit(props.resource ? 'updated' : 'created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const confirmSetItemCost = async () => {
      await useConfirm({
        title: 'Confirm Updating all related Order Item Cost?',
        content: `All related order items cost will be set to this price cost. That means historic values (if any) will be overriden to "${props.resource.computed_cost}". You should do this when you found out that you have forgotten to set the wanted cost.`,
      })

      await setItemCost(props.resource.id)

      useNotifySuccess({ content: 'Updated order item costs.' })
    }

    return {
      confirmSetItemCost,
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
