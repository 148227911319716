<template>
  <div>
    <div
      v-for="message in messages"
      :key="message.id"
      class="d-flex"
    >
      <b>{{ message.distributor.slug }}: </b>
      <div
        class="short-content"
        v-html="t(message.content)"
      >
      </div>
      <a
        class="ml-2"
        @click="confirmRemoveMessage(message)"
      >Remove</a>
    </div>

    <template v-if="!messages.length">
      No Distributor specific message.
    </template>

    <div>
      <a @click="addMessageActive = true">Add Message +</a>

      <AttachMessageAside
        :active="addMessageActive"
        :position="position"
        :path="path"
        :attach-ids="attachIds"
        :ignore-message-ids="messages.map(m => m.id)"
        @aside="(bool) => addMessageActive = bool"
        @changed="() => { $emit('updated'); addMessageActive = false; }"
      />

      <router-link
        class="ml-4"
        :to="{ name: 'distributor-message-list' }"
      >
        Create Message >
      </router-link>
    </div>
  </div>
</template>

<script>
import { useConfirm } from '@/composables'
import AttachMessageAside from '@/modules/distributor/views/distributor-message/AttachMessageAside.vue'
import { t } from '@/plugins/i18n'
import { detachDistributorMessage } from '@api/distributor/message'
import { ref } from '@vue/composition-api'

export default {
  components: { AttachMessageAside },

  props: {
    position: {
      type: String,
      default: 'product',
    },
    path: {
      type: String,
      default: 'products',
    },
    messages: {
      type: Array,
      default: () => [],
    },
    attachIds: {
      type: Array,
      default: () => [],
    },
    detachIds: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const addMessageActive = ref(false)

    const confirmRemoveMessage = async message => {
      await useConfirm({
        title: 'Confirm remove this message?',
        content: 'You can always add it back later.',
      })
      detachDistributorMessage(message.id, props.path, { detach_ids: props.detachIds }).then(() => emit('updated'))
    }

    return {
      t,
      addMessageActive,
      confirmRemoveMessage,
    }
  },
}
</script>
