import { exportVouchers as exportApi } from '@api/product'
import { ref } from '@vue/composition-api'

export default function useVoucher() {
  const loading = ref(false)

  const exportVouchers = async query => {
    loading.value = true
    await exportApi(query)
      .then()
      .finally(() => {
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  return {
    exportVouchers,
  }
}
