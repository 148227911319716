<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <plan-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :cols="12"
      :sku="sku"
      @submit="handleSubmit"
    >
      <sync-feature-form
        v-if="resource"
        :selected-ids="resource.features.map(f => f.id)"
        :sync-base-path="`/admin/plans/${resource.id}/features/sync`"
        class="mb-5"
        :cols="12"
      />
    </plan-form>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import SyncFeatureForm from '@/modules/product/views/plan-resource/SyncFeatureForm.vue'
import { updatePlan } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PlanForm from './PlanForm.vue'

export default {
  components: { PlanForm, SyncFeatureForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    sku: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form }

      await updatePlan(props.resource.id, data)
        .then(res => {
          emit('updated', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
