<template>
  <div v-if="product">
    <sync-attribute-type-form
      :selected-ids="product.attribute_types.map(tp => tp.id)"
      :sync-base-path="`/admin/products/${product.id}/attribute-types/sync`"
      :sync-able="syncAble"
      class="mb-7"
      @change="typeChanged"
    >
      <span slot="title">
        Product: {{ product.title.en }}
      </span>
    </sync-attribute-type-form>

    <sync-attribute-value-form
      v-for="(sku, index) in product.skus"
      :key="sku.id"
      :types="types"
      :values="sku.attribute_values"
      :selected-ids="sku.attribute_values.map(tp => tp.id)"
      :sync-base-path="`/admin/skus/${sku.id}/attribute-values/sync`"
      :sync-able="syncAble"
      class="mb-2"
      @change="ids => valuesChanged(index, ids)"
    >
      <span slot="title">
        SKU: {{ sku.name.en }}
      </span>
    </sync-attribute-value-form>

    <div class="d-flex justify-center mt-5">
      <v-btn
        color="primary"
        :disabled="!!invalid || keyDuplicated"
        @click="toggleSync"
      >
        Confirm
      </v-btn>
    </div>

    <p
      v-if="invalid"
      class="text-center mt-2"
    >
      Some Skus has <b>no matching value</b> for type: <b>{{ t(invalid.name) }}</b>
    </p>

    <p
      v-if="keyDuplicated"
      class="text-center mt-2"
    >
      Duplicated values SET found: {{ idKeys }}, please make sure each SKU has unique set of value(s)
      <br>e.g. [XL, Red] | [XL, Black] | [XS, Red]
      <br>e.g. [XL] | [M] | [XS]
    </p>
  </div>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import store from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import SyncAttributeTypeForm from '@/modules/product/views/product-resource/SyncAttributeTypeForm.vue'
import SyncAttributeValueForm from '@/modules/product/views/product-resource/SyncAttributeValueForm.vue'
import { computed, ref } from '@vue/composition-api'
import uniq from 'lodash/uniq'

export default {
  components: {
    SyncAttributeTypeForm,
    SyncAttributeValueForm,
  },

  props: {
    product: { type: Object, required: true },
  },

  setup(props) {
    const formReady = ref(true)
    const syncAble = ref(false)

    const typeIds = ref(props.product.attribute_types.map(tp => tp.id))
    const types = computed(() => store.state.attributeTypes.filter(tp => typeIds.value.includes(tp.id)))

    const skuTypeIds = ref(props.product.skus.map(s => s.attribute_values.map(v => v.attribute_type_id)))
    const invalidTypeId = computed(() => {
      let found = null
      typeIds.value.forEach(id => {
        skuTypeIds.value.forEach(arr => {
          if (!arr.includes(id)) {
            found = id
            console.log('cannot find type id', id, arr)
          }
        })
      })

      return found
    })
    const invalid = computed(() => types.value.find(tp => tp.id === invalidTypeId.value))

    const typeChanged = ids => {
      typeIds.value = ids
    }

    const idToKeys = ids => ids.sort().join('-')

    const idKeys = ref(props.product.skus.map(s => idToKeys(s.attribute_values.map(v => v.id))))

    const keyDuplicated = ref(false)
    const checkDuplicated = () => {
      keyDuplicated.value = uniq(idKeys.value).length !== idKeys.value.length
    }
    checkDuplicated()

    const valuesChanged = (index, ids) => {
      const matchValues = store.state.allValues.filter(v => ids.includes(v.id))

      // skuTypeIds.value[index] = matchValues.map(v => v.attribute_type_id)
      skuTypeIds.value = skuTypeIds.value.map((sids, i) => {
        if (i === index) return matchValues.map(v => v.attribute_type_id)

        return sids
      })

      idKeys.value[index] = idToKeys(ids)
      checkDuplicated()
    }

    const toggleSync = () => {
      syncAble.value = true
      setTimeout(() => {
        syncAble.value = false
        useNotifySuccess({ content: 'Attribute Update Succeeded.' })
      }, 100)
    }

    return {
      types,
      invalid,
      invalidTypeId,
      typeIds,
      skuTypeIds,

      formReady,

      syncAble,
      toggleSync,
      typeChanged,
      valuesChanged,

      t,
      idKeys,
      keyDuplicated,
    }
  },
}
</script>
