<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Name"
          class="mt-5"
        />

        <translatable-editor
          v-model="form.description"
          label="Description. Show (?) and user may click to see the details."
          class="mt-5"
        />

        <v-text-field
          v-model="form.sort"
          label="Sort"
          outlined
          class="mt-5"
        />

        <div class="d-flex">
          <v-text-field
            v-model="form.min_pick"
            label="Min Pick"
            :hint="`Users must pick at least N (${form.min_pick}) Skus from this package, in order to checkout / add to cart. (0=can pick none)`"
            outlined
            class="mt-5 mr-2"
            :rules="[between(form.min_pick, 0, 999999)]"
          />

          <v-text-field
            v-model="form.max_pick"
            label="Max Pick"
            :hint="`Users can pick upto N (${form.max_pick}) Skus from this package.`"
            outlined
            class="mt-5 ml-2"
            :rules="[between(form.max_pick, form.min_pick || 1, 999999)]"
          />
        </div>

        <ul>
          <li>max_pick = min_pick, ordinary setting.</li>
          <li>max_pick > min_pick, You want to combine with weight system, or attract users to select more SKUs for an additional fee.</li>
        </ul>

        <div class="d-flex">
          <v-text-field
            v-model="form.min_weight"
            label="Min Weight"
            :hint="`Users must select weight at least N (${form.min_weight}) weights from combination of SKUs, in order to checkout / add to cart. (0=Dont use weight system)`"
            outlined
            class="mt-5 mr-2"
            :rules="[between(form.min_weight, 0, 999999)]"
          />

          <v-text-field
            v-model="form.max_weight"
            label="Max Weight"
            :hint="`Users can select weight upto N (${form.max_weight}) weights from combination of SKUs.`"
            outlined
            class="mt-5 ml-2"
            :rules="[between(form.max_pick, form.min_pick || 1, 999999)]"
          />
        </div>

        <v-switch
          v-model="form.main"
          color="primary"
          label="Main. It affects the product listing price display."
        ></v-switch>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor } from '@/components'
import { TranslatableInput } from '@/components'
import { TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { between } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslatableInput,
    TranslatableEditor,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('name', useTranslatable('')),
      ...field('description', useTranslatable('')),
      ...field('sort', 1),
      ...field('min_pick', 0),
      ...field('max_pick', 1),
      ...field('min_weight', 0),
      ...field('max_weight', 0),
      ...field('main', 0),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      between,
    }
  },
}
</script>
