<template>
  <v-form ref="formElem">
    <!-- <translation-locales /> -->
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <h4>Plan</h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            Plan Type: <b>{{ form.type }}</b>
          </v-col>
          <v-col :cols="cols">
            <v-select
              v-model="form.price_group_id"
              label="Price Group"
              :items="priceGroupOptions"
              item-text="title"
              item-value="value"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <v-row>
              <v-col :cols="8">
                <v-text-field
                  v-model="form.interval_count"
                  label="Billing Interval"
                  hint="Interval value N (N days, N months, N years)"
                  :rules="[between(form.interval_count,1,999)]"
                ></v-text-field>
              </v-col>

              <v-col :cols="4">
                <v-select
                  v-model="form.interval_unit"
                  label="Interval Unit"
                  :items="unitOptions"
                  item-text="title"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>

              <v-col :cols="8">
                <v-text-field
                  v-model="form.delay_days"
                  label="Membership Delay N Days"
                  hint="When membership first purchased & started, delay N days as start date. 3 for medical card"
                  :rules="[between(form.delay_days,0,999)]"
                ></v-text-field>
              </v-col>

              <v-col :cols="8">
                <v-text-field
                  v-model="form.card_quota"
                  label="Max cardholders per membership"
                  hint="4 for family plan. 1 for individual"
                  :rules="[between(form.card_quota,0,999)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <slot></slot>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { between, required } from '@core/utils/validation'
import { mdiCloseCircle } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'

// import { TranslationLocales } from '@/components'
import usePriceForm from '../price-resource/usePriceForm'

export default {
  // components: { TranslationLocales },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    sku: {
      type: Object,
      required: true,
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      //   sku_id: props.resource?.sku_id || null,
      type: 'medical',
      price_group_id: props.resource?.price_group_id || props.sku.id,
      interval_count: props.resource?.interval_count || 30,
      delay_days: props.resource ? props.resource.delay_days : 3,
      interval_unit: props.resource?.interval_unit || 'day',
      card_quota: props.resource?.card_quota || 1,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const { priceGroups, loadPriceGroups } = usePriceForm()

    onMounted(() => {
      loadPriceGroups(props.sku)
    })

    const priceGroupOptions = computed(() => priceGroups.value.map(group => ({ title: group.name.en, value: group.id })))

    const unitOptions = [{ title: 'Day', value: 'day' }]

    return {
      form,
      formElem,
      validate,
      between,
      required,

      priceGroupOptions,
      unitOptions,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>
