<template>
  <v-form>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <v-autocomplete
              v-model="form.sync_ids"
              placeholder="Select 0 or more channels"
              :items="channelsOptions"
              item-text="title"
              item-value="value"
              label="Channels"
              outlined
              chips
              multiple
              hide-details
              :disabled="disabled"
              @change="handleSync"
            ></v-autocomplete>
            <!-- <v-select
              v-model="form.type"
              placeholder="Display Section"
              :items="typeOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
            ></v-select> -->
            <!-- <v-text-field
              v-model="searchText"
              placeholder="Search Channel key, name"
            /> -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import channelStore from '@/modules/channel/store'
import store from '@/store'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/products/1/sync-channels
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    // const typeOptions = [
    //   { title: 'Service Category', value: 'service' },
    //   { title: 'Tangible Category', value: 'tangible' },
    // ]
    if (!channelStore.state.channels.length) {
      store.dispatch('channel/fetchAllChannels')
    }

    const searchText = ref('')

    const matchText = channel => {
      const q = searchText.value.toLowerCase()
      const code = channel.code.toLowerCase()
      const name = JSON.stringify(channel.name).toLowerCase()

      return name.includes(q) || code.includes(q) || props.selectedIds.includes(channel.id)
    }

    const channelsOptions = computed(() => channelStore.state.channels.filter(matchText).map(channel => ({ title: channel.code, value: channel.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('change')
      })
    }

    return {
      form,
      searchText,

      // typeOptions,
      channelsOptions,
      handleSync,
    }
  },
}
</script>
