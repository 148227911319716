<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Display Name"
          class="mt-5"
        />

        <v-switch
          v-model="form.show_description"
          label="Show Description"
        >
        </v-switch>

        <translatable-editor
          v-show="form.show_description"
          v-model="form.description"
          label="Description (e.g. describe how the user should pick.)"
          class="mt-5"
        />
        <v-autocomplete
          v-model="form.parent_id"
          label="Parent Group"
          class="mt-5"
          :items="optionGroupOptions"
          clearable
          item-text="title"
          item-value="value"
          outlined
        ></v-autocomplete>

        <v-alert
          v-if="(form.min_pick > 0 || form.max_pick < 99) && changeable"
          color="warning"
        >
          Note: You should rely on the existing min_max_pick via the package's system, <b>unless you need more sophisticated, in-depth </b> min_max_pick via option grouping. You need to test that clearly on the front end since the backend don't really care about option group.
        </v-alert>
        <p v-if="!changeable">
          You don't need to change these default values, unless you absolutely know that your client need more complex grouping. (e.g. AXA's core + optional). Otherwise package itself will do. <a @click="changeable = true">CHANGE</a>
        </p>

        <div>
          <v-text-field
            v-model="form.min_pick"
            label="Min Pick"
            tpye="number"
            :hint="`Users must pick at least N (${form.min_pick}) Skus from this option, in order to checkout / add to cart. 0 will do.`"
            outlined
            :rules="[between(form.min_pick, 0, 999999)]"
            :disabled="!changeable"
          />

          <v-text-field
            v-model="form.max_pick"
            label="Max Pick"
            tpye="number"
            :hint="`Users can pick upto N (${form.max_pick}) Skus from this option. 99 will do.`"
            outlined
            :rules="[between(form.max_pick, form.min_pick || 1, 999999)]"
            :disabled="!changeable"
          />

          <v-text-field
            v-model="form.notes"
            label="Notes"
            outlined
          />
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import useOptionGroup from '@/modules/common/views/option-group/useOptionGroupList'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslatableEditor, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, defValue) => ({
      [name]: props.resource ? props.resource[name] : defValue,
    })

    const initialForm = {
      name: useTranslatable(props.resource, 'name'),
      description: useTranslatable(props.resource, 'description'),
      ...field('show_description', 1),
      ...field('min_pick', 0),
      ...field('max_pick', 99),
      ...field('parent_id', null),
      ...field('notes', ''),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const { optionGroupOptions, loadOptionGroups } = useOptionGroup({ level: 1, take: 999 })
    loadOptionGroups()

    return {
      form,
      formElem,

      validate,
      required,
      between,

      optionGroupOptions,
      changeable: ref(false),
    }
  },
}
</script>
