<template>
  <div>
    <plan-aside
      v-if="aside === 'plan'"
      :active="true"
      :sku="sku"
      :resource="planData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />

    <p v-if="!plans.length">
      Please create SKU first.
    </p>

    <v-card class="mb-2">
      <v-card-title>
        <span>{{ sku.name.en }}'s Plans</span>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="plans"
        :items-per-page="99"
        hide-default-footer
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <!-- type -->
        <template #[`item.type`]="{item}">
          <div class="d-flex align-center">
            {{ item.type }}
          </div>
        </template>

        <!-- card_quota -->
        <template #[`item.card_quota`]="{item}">
          <div class="d-flex align-center">
            {{ item.card_quota }}
          </div>
        </template>

        <!-- interval_count -->
        <template #[`item.interval_count`]="{item}">
          <div class="d-flex align-center">
            {{ item.interval_count }} {{ item.interval_unit }}
          </div>
        </template>

        <!-- price_group_id -->
        <template #[`item.price_group_id`]="{item}">
          <div class="d-flex align-center">
            {{ t(item.price_group.name) }}
          </div>
        </template>

        <!-- features -->
        <template #[`item.features`]="{item}">
          <div class="d-flex align-center">
            <v-chip
              v-for="feature in item.features"
              :key="feature.key"
              x-small
            >
              {{ t(feature.name) }}
            </v-chip>
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item, 'plan')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update Plan</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus,
} from '@mdi/js'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'

import { t } from '@/plugins/i18n'
import { dateSimple, hourToString } from '@core/utils/filter'
import PlanAside from '../../plan-resource/PlanAside.vue'
import usePlanForm from '../../plan-resource/usePlanForm'

export default {
  components: { PlanAside },

  filters: { hourToString, dateSimple },

  props: {
    product: { type: Object, required: true },
    sku: { type: Object, required: true },
  },

  setup(props, { emit }) {
    const { plans, loadPlans } = usePlanForm()

    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'TYPE', value: 'type', sortable: false },
      { text: 'CARD QUOTA', value: 'card_quota', sortable: false },
      { text: 'INTERVAL', value: 'interval_count', sortable: false },
      { text: 'DELAY DAYS', value: 'delay_days', sortable: false },
      { text: 'PRICE GROUP', value: 'price_group_id', sortable: false },
      { text: 'FEATURES', value: 'features', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const planData = ref(null)
    const aside = ref('') // plan, plan, generator

    const closeAside = bol => {
      if (!bol) {
        planData.value = null
        aside.value = null
      }
    }

    const toggleAside = group => {
      planData.value = group
      aside.value = 'plan'
    }

    const ready = ref(false)

    const refresh = async () => {
      ready.value = false
      await loadPlans(props.sku)

      // await loadPlans(planData.value)
      closeAside(false)
      ready.value = true
    }

    const planUpdated = () => {
      emit('updated')
      refresh()
    }

    watch(props.sku, sku => {
      console.log('sku changed', sku)
      refresh()
    })

    onMounted(() => {
      refresh()
    })

    return {
      tableColumnHeaders,
      plans,
      planData,
      aside,
      ready,
      refresh,
      planUpdated,
      closeAside,
      toggleAside,
      t,
      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
