<template>
  <v-row>
    <v-col>
      <voucher-aside
        v-if="aside === 'voucher'"
        :active="true"
        :sku="sku"
        :resource="voucher"
        @updated="emitUpdated"
        @aside="bol => { closeAside(bol); !bol && refresh() } "
      />
    </v-col>
    <v-col :cols="12">
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="vouchers"
        :options.sync="options"
        :server-items-length="vouchers.length"
        :loading="loading"
        :height="400"
        show-select
        fixed-header
      >
        <template #[`item.from`]="{item}">
          <span v-if="item.applicable_at">{{ item.applicable_at | dateSimple }}</span>
          <span v-else>Any time.</span>
        </template>

        <template #[`item.to`]="{item}">
          <span v-if="item.last_purchasable_at">{{ item.last_purchasable_at | dateSimple }}</span>
          <span v-else>Never</span>
        </template>

        <template #[`item.expire`]="{item}">
          <span v-if="item.expires_at">{{ item.expires_at | dateSimple }}</span>
          <span v-else>Never expires</span>
        </template>

        <template #[`item.redeemed_at`]="{item}">
          <span v-if="item.redeemed_at">{{ item.redeemed_at | dateSimple }}</span>
          <span v-else>Not used</span>
        </template>

        <template #[`item.code`]="{item}">
          {{ item.code }}
        </template>

        <template #[`item.owner`]="{item}">
          <span v-if="item.owner">{{ item.owner.email }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <span v-if="item.status">{{ item.status }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="resendVoucher(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                  </v-icon>
                  <span>Resend to...</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="trashVoucher(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-col>

    <v-col>
      <v-btn
        v-if="selectedRows.length"
        color="error"
        :loading="loading"
        @click="bulkTrashSelected"
      >
        Bulk Delete
      </v-btn>
    </v-col>

    <resend-voucher-dialog
      v-if="sendVoucher"
      :show="true"
      :voucher-id="sendVoucher.id"
      :default-email="defaultEmail"
      @close="sendVoucher = false"
    >
    </resend-voucher-dialog>
  </v-row>
</template>

<script>
import { destroyVoucher } from '@/api/product'
import { useNotifyErrors } from '@/composables'
import { dateSimple } from '@core/utils/filter'
import { mdiDeleteOutline, mdiDotsVertical, mdiPencil } from '@mdi/js'
import {
  onMounted, ref, watch,
} from '@vue/composition-api'
import moment from 'moment'
import ResendVoucherDialog from './ResendVoucherDialog.vue'
import VoucherAside from './VoucherAside.vue'
import useVoucherForm from './useVoucherForm'

export default {
  components: { VoucherAside, ResendVoucherDialog },
  filters: { dateSimple },
  props: {
    sku: {
      type: Object,
      required: true,
    },
    rule: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const date = ref(moment().format('YYYY-MM-DD'))

    const { loadVouchers, vouchers, loading } = useVoucherForm()

    const refresh = () => loadVouchers(props.sku, {
      started_at_from: moment(date.value).startOf('month').format(),
      end_at_to: moment(date.value).endOf('month').format(),
      take: 999,
    })

    const selectedRows = ref([])

    const trashVoucher = voucher => destroyVoucher(voucher.id)
      .then(() => emit('updated'))
      .catch(useNotifyErrors)

    const bulkTrashSelected = async () => {
      loading.value = true
      const promises = selectedRows.value.filter(v => !v.owner).map(v => destroyVoucher(v.id).catch(useNotifyErrors))
      await Promise.all(promises).catch(err => {
        useNotifyErrors(err)
      })
      selectedRows.value = []
      emit('updated')
      loading.value = false
    }

    onMounted(() => refresh())

    watch([date], refresh)

    const tableColumns = [
      { text: 'USABLE SINCE', value: 'from' },
      { text: 'STOP PURCHASE', value: 'to' },
      { text: 'EXPIRES', value: 'expire' },
      { text: 'REDEEMED AT', value: 'redeemed_at' },
      { text: 'CODE', value: 'code' },
      { text: 'OWNER', value: 'owner' },
      { text: 'STATUS', value: 'status' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })

    const voucher = ref(null)
    const aside = ref('') // voucher_group, voucher, generator

    const closeAside = bol => {
      if (!bol) {
        voucher.value = null
        aside.value = null
      }
    }

    const emitUpdated = () => {
      closeAside(false)
      emit('updated')
    }

    const toggleAside = v => {
      voucher.value = v
      aside.value = 'voucher'
    }

    const sendVoucher = ref(false)
    const defaultEmail = ref('')
    const resendVoucher = v => {
      sendVoucher.value = v
      defaultEmail.value = v.owner?.email || null
    }

    return {
      date,
      vouchers,
      loading,

      sendVoucher,
      defaultEmail,
      resendVoucher,
      trashVoucher,
      bulkTrashSelected,

      tableColumns,
      selectedRows,
      options,

      toggleAside,
      closeAside,
      emitUpdated,
      aside,
      voucher,

      // icons
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
