<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <h4>Make voucher become...</h4>
            <p class="mt-5">
              1) usable or applicable since: <b>{{ form.applicable_at || 'Any time.' }}</b>
              <v-icon
                v-if="form.applicable_at"
                @click="form.applicable_at = null"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </p>
            <v-date-picker
              v-model="form.applicable_at"
              color="primary"
            ></v-date-picker>

            <p class="mt-5">
              2) stop purchase since: <b>{{ form.last_purchasable_at || 'Never.' }}</b>
              <v-icon
                v-if="form.last_purchasable_at"
                @click="form.last_purchasable_at = null"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </p>
            <v-date-picker
              v-model="form.last_purchasable_at"
              color="primary"
            ></v-date-picker>

            <p class="mt-5">
              3) expiry date (Reference only): <b>{{ form.expires_at || 'Never expired.' }}</b>
              <v-icon
                v-if="form.expires_at"
                @click="form.expires_at = null"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </p>
            <v-date-picker
              v-model="form.expires_at"
              color="primary"
            ></v-date-picker>

            <v-text-field
              v-model="form.code"
              label="Voucher Code"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { mdiCloseCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { between, required } from '@core/utils/validation'
import moment from 'moment'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      applicable_at: props.resource && props.resource.applicable_at ? moment(props.resource.applicable_at).format('YYYY-MM-DD') : null,
      last_purchasable_at: props.resource && props.resource.last_purchasable_at ? moment(props.resource.last_purchasable_at).format('YYYY-MM-DD') : null,
      expires_at: props.resource && props.resource.expires_at ? moment(props.resource.expires_at).format('YYYY-MM-DD') : null,
      code: props.resource && props.resource.code ? props.resource.code : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      between,
      required,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>
