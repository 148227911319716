import { fetchAllOptionGroups } from '@/api/common'
import { useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useOptionGroupsList(query = {}) {
  const optionGroupListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'NAME', value: 'name', sortable: false },
    { text: 'CHILDREN', value: 'children', sortable: false },
    { text: 'PICKS', value: 'min_pick' },
    { text: 'DESCRIPTION', value: 'show_description' },
    { text: 'NOTES', value: 'notes', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const levelFilter = ref(cached[2] || query.level || 1)
  const levelOptions = ref([
    { title: 'Root (1)', value: 1 },
    { title: 'Main (2)', value: 2 },
    { title: 'Sub (3)', value: 3 },
  ])
  const totalLocal = ref([])
  const selectedRows = ref([])

  const optionGroupOptions = computed(() =>
    optionGroupListTable.value.map(og => ({
      title: og.name.en,
      value: og.id,
    })),
  )

  // fetch data
  const loadOptionGroups = () =>
    fetchAllOptionGroups(
      useTableOptions(options.value, {
        search: searchQuery.value,
        level: levelFilter.value,
        ...query,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        optionGroupListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false

        return records
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery, levelFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadOptionGroups()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    optionGroupListTable,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadOptionGroups,
    levelFilter,
    levelOptions,
    optionGroupOptions,
  }
}
