<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.sort"
          hint="Sort ASC position"
          label="Sort"
          outlined
          class="mt-5"
        />

        <v-text-field
          v-model="form.weight"
          label="Weight"
          hint="The sum weight of selected SKUs must match with min_max_weight of its package."
          outlined
          class="mt-5"
        />

        <v-autocomplete
          v-model="form.sku_id"
          label="Selectable SKU"
          :items="skuOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-autocomplete>

        <v-select
          v-model="form.option_group_id"
          label="Option Group (nullable)"
          :items="optionGroupOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
        ></v-select>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import useOptionGroupsList from '@/modules/common/views/option-group/useOptionGroupList';
import { } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    skuOptions: {
      // default resource
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('sort', 1),
      ...field('weight', 0),
      ...field('sku_id', props.skuOptions[0]?.value),
      ...field('option_group_id', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const { optionGroupOptions, loadOptionGroups, levelFilter } = useOptionGroupsList({ take: 999 })
    levelFilter.value = undefined
    loadOptionGroups()

    return {
      form,
      formElem,
      validate,
      optionGroupOptions,
    }
  },
}
</script>
