<template>
  <v-card>
    <!-- <v-card-title>
      <h1>Product Media Gallery</h1>
    </v-card-title> -->

    <v-card-text>
      <div class="d-flex justify-space-between">
        <div
          v-if="product"
          class="mb-5"
        >
          product: {{ product.title.en }}
        </div>

        <div
          class="mb-5"
        >
          Mode:
          <template
            v-if="resource"
          >
            <v-chip>
              updating #{{ resource.id }}
            </v-chip>
            <a
              class="ml-3"
              @click="resource = null"
            >Switch to Upload</a>
            <span class="ml-3">|</span>
            <a
              class="ml-3"
              @click="confirmDelete(resource)"
            >Delete this media</a>
          </template>
          <v-chip v-else>
            Upload
          </v-chip>
        </div>
      </div>

      <div class="height-placeholder">
        <media-form
          v-if="formReady"
          :mode="resource ? 'update' : 'create'"
          :resource="resource"
          :loading="loading"
          :disabled-fields="[ resource ? 'file' : '']"
          :errors="errors"
          :sku-options="skuOptions"
          @submit="handleSubmit"
          @cancel="resource = false"
        />
      </div>

      <div class="d-flex justify-space-between mt-10">
        <h2>
          Current Media
        </h2>
        <v-autocomplete
          v-model="showSkuId"
          placeholder="Filter display Sku"
          :items="skuFilterOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          style="max-width: 200px"
        ></v-autocomplete>
      </div>
      <v-row class="mt-5">
        <v-col
          v-for="medium in displayMedia"
          :key="medium.id"
          cols="2"
        >
          <v-badge>
            <template #badge>
              <v-icon
                class="cursor-pointer"
                @click="confirmDelete(medium)"
              >
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <img
              :src="medium.instance.thumbnail"
              alt=""
              class="img img-thumbnail"
            >
          </v-badge>

          <div>
            <div v-if="medium.position === 'carousel'">
              <v-icon>
                {{ icons.mdiViewCarouselOutline }}
              </v-icon>
              <v-badge
                :content="medium.sort"
              >
                <small>
                  Carousel
                </small>
              </v-badge>
            </div>

            <div v-if="medium.position === 'thumbnail'">
              <v-icon>
                {{ icons.mdiStar }}
              </v-icon>
              <small>
                thumbnail
              </small>
            </div>

            <div v-if="medium.device === 'mobile'">
              <v-icon>
                {{ icons.mdiCellphone }}
              </v-icon>
              <small>Mobile</small>
            </div>

            <div v-if="medium.device === 'desktop'">
              <v-icon>
                {{ icons.mdiDesktopMac }}
              </v-icon>
              <small>Desktop</small>
            </div>

            <div v-if="medium.sku_id">
              <v-icon>{{ icons.mdiAlphaSBox }}</v-icon>
              <small v-if="sku(medium)">
                {{ t(sku(medium).name) }}
              </small>
            </div>

            <div v-else>
              <v-icon>{{ icons.mdiAlphaPBox }}</v-icon>
              <small>
                (general)
              </small>
            </div>
          </div>

          <v-btn @click="edit(medium)">
            Edit
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  useConfirm, useExtractErrors, useNotifyErrors, useNotifySuccess,
} from '@/composables'
import { t } from '@/plugins/i18n'
import { destroyPhoto, storePhoto, updatePhoto } from '@api/product'
import {
  mdiAlphaPBox, mdiAlphaSBox, mdiCellphone, mdiClose, mdiDesktopMac, mdiStar, mdiViewCarouselOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import MediaForm from './ProductMediaForm.vue'

export default {
  components: { MediaForm },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const productId = props.product.id

    const loading = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const errors = ref([])

    const handleStore = form => {
      const formData = new FormData()

      Object.entries(form).forEach(([key, value]) => {
        if (value) formData.append(key, value)
      })

      formData.append('product_id', productId)

      return storePhoto(formData)
    }

    const handleUpdate = form => updatePhoto(resource.value.id, { ...form })

    const handleSubmit = async form => {
      loading.value = true
      errors.value = []

      const action = resource.value ? handleUpdate : handleStore

      await action(form)
        .then(() => {
          emit('updated')
          useNotifySuccess({ content: 'Media Updated.' })
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })

      loading.value = false
    }

    const refreshForm = () => {
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const skuOptions = props.product.skus.map(s => ({
      title: `${s.name.en}${!s.active ? ' (inactive)' : ''}${s.status !== 'published' ? ` (${s.status})` : ''}`,
      value: s.id,
    }))

    const showSkuId = ref(null)
    const skuFilterOptions = [...skuOptions]

    skuOptions.push({
      title: 'Not attached to SKU.',
      value: null,
    })

    const displayMedia = computed(() => props.product.media.filter(m => m.sku_id === showSkuId.value || !showSkuId.value || !m.sku_id))

    const edit = medium => {
      resource.value = medium
      refreshForm()
    }

    const sku = medium => props.product.skus.find(s => s.id === medium.sku_id)

    const confirmDelete = async medium => {
      await useConfirm({
        title: 'Confirm Action',
        content: 'Confirm delete this media?',
      })

      destroyPhoto(medium.id)
        .then(() => {
          emit('updated')
        })
        .catch(useNotifyErrors)
    }

    return {
      errors,
      loading,
      handleSubmit,

      resource,
      edit,
      skuOptions,
      showSkuId,
      skuFilterOptions,
      displayMedia,
      formReady,

      confirmDelete,
      sku,
      t,
      icons: {
        mdiClose,
        mdiAlphaSBox,
        mdiAlphaPBox,
        mdiViewCarouselOutline,
        mdiStar,
        mdiCellphone,
        mdiDesktopMac,
      },
    }
  },
}
</script>

<style scoped>
.img {
  max-width: 100%;
}
.height-placeholder {
  min-height: 360px;
}
</style>
