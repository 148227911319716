<template>
  <div>
    <voucher-group-aside
      v-if="aside === 'voucher_group'"
      :active="true"
      :sku="sku"
      :resource="voucherGroupData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />

    <voucher-group-generator-aside
      v-if="aside === 'generator'"
      :active="true"
      :sku="sku"
      :resource="voucherGroupData"
      @changed="voucherUpdated"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    >
    </voucher-group-generator-aside>

    <model-exporter-aside
      v-if="aside === 'exporter'"
      :active="true"
      :resource="{
        sku_id: sku.id
      }"
      :export-resource="exportVouchers"
      :enabled-plugins="false"
      @change="aside = false"
      @aside="(bol) => { if (!bol) { aside = ''}}"
    >
    </model-exporter-aside>

    <v-card class="mb-2">
      <v-card-title>
        <div>
          <span>{{ sku.name.en }}'s Voucher Groups</span>
          <v-chip class="ml-2">
            STOCK: {{ sku.stock }}
          </v-chip>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="(product.nature === 'voucher') && voucherGroups.length === 0"
          color="primary"
          class="mb-4 me-3"
          @click="toggleAside(null, 'voucher_group')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Group</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="voucherGroups"
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <!-- stock -->
        <template #[`item.stock`]="{item}">
          <div class="d-flex align-center">
            {{ item.stock }} ({{ item.stock_original }})
          </div>
        </template>

        <!-- template -->
        <template #[`item.template`]="{item}">
          <div class="d-flex align-center">
            {{ item.template ? item.template.nicename : 'n/a' }}
          </div>
        </template>

        <!-- applicable -->
        <template #[`item.from`]="{item}">
          <div class="d-flex align-center">
            <span v-if="item.applicable_at">{{ item.applicable_at | dateSimple }}</span>
            <span v-else>Any time.</span>
          </div>
        </template>

        <!-- expires_at -->
        <template #[`item.to`]="{item}">
          <div class="d-flex align-center">
            <span v-if="item.last_purchasable_at">{{ item.last_purchasable_at | dateSimple }}</span>
            <span v-else>Never.</span>
          </div>
        </template>

        <!-- expires_at -->
        <template #[`item.expire`]="{item}">
          <div class="d-flex align-center">
            <span v-if="item.expires_at">{{ item.expires_at | dateSimple }}</span>
            <span v-else>Never expires</span>
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item, 'voucher_group')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update Group</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="toggleGroupGenerator(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCog }}
                  </v-icon>
                  <span>Generate Vouchers</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-items-center">
        <span>
          {{ t(sku.name) }}'s all Vouchers
        </span>

        <v-btn
          slot="title"
          @click="aside = 'exporter'"
        >
          Export Vouchers
        </v-btn>
      </v-card-title>
      <v-card-text>
        <voucher-previewer
          v-if="ready"
          :rule="null"
          :sku="sku"
          @updated="refresh"
        >
        </voucher-previewer>
      </v-card-text>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCog, mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus,
} from '@mdi/js'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'

import { ModelExporterAside } from '@/components'
import { t } from '@/plugins/i18n'
import { dateSimple, hourToString } from '@core/utils/filter'
import useVoucher from '../../../composables/useVoucher'
import useVoucherForm from '../../voucher-resource/useVoucherForm'
import useVoucherGroupForm from '../../voucher-resource/useVoucherGroupForm'
import VoucherGroupAside from '../../voucher-resource/VoucherGroupAside.vue'
import VoucherGroupGeneratorAside from '../../voucher-resource/VoucherGroupGeneratorAside.vue'
import VoucherPreviewer from '../../voucher-resource/VoucherPreviewer.vue'

export default {
  components: {
    VoucherGroupAside, VoucherGroupGeneratorAside, VoucherPreviewer, ModelExporterAside,
  },

  filters: { hourToString, dateSimple },

  props: {
    product: { type: Object, required: true },
    sku: { type: Object, required: true },
  },

  setup(props, { emit }) {
    const { voucherGroups, loadVoucherGroups } = useVoucherGroupForm()
    const { vouchers } = useVoucherForm()
    const { exportVouchers } = useVoucher()

    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'STOCK', value: 'stock', sortable: false },
      { text: 'PDF TEMPLATE', value: 'template', sortable: false },
      { text: 'USABLE SINCE', value: 'from', sortable: false },
      { text: 'STOP PURCHASE', value: 'to', sortable: false },
      { text: 'EXPIRES AT', value: 'expire', sortable: false },
      { text: 'ACTIONS', value: 'action', sortable: false },
    ])

    const voucherGroupData = ref(null)
    const aside = ref('') // voucher_group, voucher, generator

    const closeAside = bol => {
      if (!bol) {
        voucherGroupData.value = null
        aside.value = null
      }
    }

    const toggleAside = group => {
      voucherGroupData.value = group
      aside.value = 'voucher_group'
    }

    const toggleGroupGenerator = group => {
      voucherGroupData.value = group
      aside.value = 'generator'
    }

    const ready = ref(false)

    const refresh = async () => {
      console.log('refresh!')
      ready.value = false
      await loadVoucherGroups(props.sku)

      // await loadVouchers(voucherGroupData.value)
      closeAside(false)
      ready.value = true
    }

    const voucherUpdated = () => {
      emit('updated')
      refresh()
    }

    watch(props.sku, sku => {
      console.log('sku changed', sku)
      refresh()
    })

    onMounted(() => {
      refresh()
    })

    return {
      vouchers,
      voucherGroups,
      exportVouchers,
      tableColumnHeaders,
      voucherGroupData,
      aside,
      ready,
      refresh,
      voucherUpdated,
      closeAside,
      toggleAside,
      toggleGroupGenerator,
      t,
      icons: {
        mdiPencil,
        mdiPlus,
        mdiCog,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
