import {
  fetchSkuSlotRules, storeSlotRule, updateSlotRule
} from '@api/product'
import { ref } from '@vue/composition-api'

export default function useSlotForm() {
  const slotRuleErrors = ref([])
  const loading = ref(false)
  const slotRuleData = ref(null)
  const slotRules = ref([]) // of a sku

  /**
   * With Tangible, we store slot group first,
   * then store slot inside the group.
   * group name is irrelevant.
   */

  const updateSlotRuleForm = (slotRuleId, form) => {
    loading.value = true

    return updateSlotRule(slotRuleId, { ...form })
      .then(res => {
        slotRuleData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      }).finally(() => {
        loading.value = false
      })
  }

  const storeSlotRuleForm = form => {
    loading.value = true

    return storeSlotRule({ ...form })
      .then(res => {
        slotRuleData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      }).finally(() => {
        loading.value = false
      })
  }

  const loadSlotRules = (sku, queryParams) => fetchSkuSlotRules({ sku_id: sku.id, ...queryParams })
    .then(res => {
      const { records, pagination } = res.data.data
      slotRules.value = records

      // remove loading state

      return res
    })
    .catch(error => {
      console.log(error)

      return Promise.reject(error)
    }).finally(() => {
      loading.value = false
    })

  return {
    loading,
    slotRuleErrors,
    slotRuleData,
    slotRules,

    updateSlotRuleForm,
    storeSlotRuleForm,
    loadSlotRules,
  }
}
