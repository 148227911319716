var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'plan')?_c('plan-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.planData},on:{"changed":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),(!_vm.plans.length)?_c('p',[_vm._v(" Please create SKU first. ")]):_vm._e(),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.sku.name.en)+"'s Plans")])]),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.plans,"items-per-page":99,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.card_quota",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.card_quota)+" ")])]}},{key:"item.interval_count",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.interval_count)+" "+_vm._s(item.interval_unit)+" ")])]}},{key:"item.price_group_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.t(item.price_group.name))+" ")])]}},{key:"item.features",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},_vm._l((item.features),function(feature){return _c('v-chip',{key:feature.key,attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.t(feature.name))+" ")])}),1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleAside(item, 'plan')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update Plan")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }