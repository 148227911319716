import {
  fetchVoucherGroups, storeVoucherGroup, updateVoucherGroup
} from '@api/product'
import { ref } from '@vue/composition-api'

export default function useSlotForm() {
  const voucherGroupErrors = ref([])
  const loading = ref(false)
  const voucherGroupData = ref(null)
  const voucherGroups = ref([]) // of a sku

  /**
   * With Tangible, we store slot group first,
   * then store slot inside the group.
   * group name is irrelevant.
   */

  const updateVoucherGroupForm = (voucherGroupId, form) => {
    loading.value = true

    return updateVoucherGroup(voucherGroupId, { ...form })
      .then(res => {
        voucherGroupData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      }).finally(() => {
        loading.value = false
      })
  }

  const storeVoucherGroupForm = form => {
    loading.value = true

    return storeVoucherGroup({ ...form })
      .then(res => {
        voucherGroupData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      }).finally(() => {
        loading.value = false
      })
  }

  const loadVoucherGroups = (sku, queryParams) => fetchVoucherGroups({ sku_id: sku.id, ...queryParams })
    .then(res => {
      const { records, pagination } = res.data.data
      voucherGroups.value = records

      // remove loading state

      return res
    })
    .catch(error => {
      console.log(error)

      return Promise.reject(error)
    }).finally(() => {
      loading.value = false
    })

  return {
    loading,
    voucherGroupErrors,
    voucherGroupData,
    voucherGroups,

    updateVoucherGroupForm,
    storeVoucherGroupForm,
    loadVoucherGroups,
  }
}
