<template>
  <div>
    <v-card class="mb-7">
      <v-card-title>
        <span>Package Bundle List</span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="product.nature === 'topup'"
          color="primary"
          class="mb-4 me-3"
          @click="aside = true; asideMode = 'package'"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add a Package</span>
        </v-btn>

        <p v-else>
          Only Topup is supported.
        </p>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumnHeaders"
          :items="product.packages"
          :items-per-page="99"
          hide-default-footer
        >
          <!-- name -->
          <template #[`item.name`]="{item}">
            <div class="d-flex align-center">
              <main-media
                :media="product.media"
                :sku-id="item.id"
                class="img img-thumbnail mr-2"
              />

              <div class="d-flex align-center">
                {{ item.name.en }}
              </div>

              <v-icon
                v-if="item.main"
                title="Main that SKUs inside affect product listing price"
              >
                {{ icons.mdiStar }}
              </v-icon>
            </div>
          </template>

          <template #[`item.mapping_count`]="{item}">
            <div
              v-for="mapping in item.mappings"
              :key="mapping.sku.id"
              class="mt-2 mb-2"
            >
              <v-chip>
                <div>
                  #{{ mapping.sort }}
                  {{ mapping.sku.name.en }}
                  <span v-if="mapping.weight">({{ mapping.weight }}w)</span>
                </div>

                <v-icon @click="setMapping(item, mapping)">
                  {{ icons.mdiPencil }}
                </v-icon>

                <v-icon @click="confirmTrashPackageMapping(mapping).then(() => $emit('updated'))">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-chip>

              <span v-if="mapping.option_group">
                [
                <a @click="setOptionGroup(mapping.option_group)"><u>{{ mapping.option_group.name.en }}</u></a>
                <span v-if="mapping.option_group.parent">
                  of
                  <a @click="setOptionGroup(mapping.option_group.parent)"><u>{{ mapping.option_group.parent.name.en }}</u></a>
                </span>
                ]
              </span>
            </div>

            <v-alert
              v-if="item.min_pick > item.mapping_count"
              color="warning"
              class="mt-2"
            >
              (Err! You must add more SKUs to fulfill min_pick: {{ item.min_pick }}, now: {{ item.mapping_count }})
            </v-alert>
          </template>

          <template #[`item.min_pick`]="{item}">
            {{ item.min_pick }}<span v-if="item.max_pick !== item.min_pick">-{{ item.max_pick }}</span>
          </template>

          <template #[`item.min_weight`]="{item}">
            <template v-if="!item.min_weight && !item.max_weight">
              -
            </template>
            <template v-else>
              {{ item.min_weight }}
              <span v-if="item.max_weight !== item.min_weight">-{{ item.max_weight }}</span>
            </template>
          </template>

          <!-- Action -->
          <template #[`item.action`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="setPackage(item)">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="setPackageMappingCreate(item)">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                    <span>Add an SKU</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="confirmTrashPackage(item).then(() => $emit('updated'))"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Trash Package</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="d-flex justify-center">
          <div style="max-width: 620px">
            <h3 class="text-center">
              Notes for Topup+Package
            </h3>
            <ul>
              <h4 class="text-center">
                -- Step 1: Origin and Package base SKUs --
              </h4>
              <li>create two <b>Topup SKU</b> (e.g. Jet Peel) with normal price (e.g. $668).</li>
              <li>Create two <b>quota rule</b> for each of them pointing to the corresponding Origin SKUs.</li>
            </ul>
            <ul>
              <h4 class="text-center">
                -- Step 2: Set Package SKU --
              </h4>
              <li>Create a new <b>Invisible Product</b> and a new <b>"Package"</b> (AXA plan).</li>
              <li>Create a new SKU (e.g. Menopause Plan) under the package product as base.</li>
              <li>Set package min_pick and this is the only SKU, with normal price as base plan price $3988. So This Base SKU is always picked.</li>
            </ul>
            <ul>
              <h4 class="text-center">
                -- Step 3: Package and Price--
              </h4>
              <li>Create <b>another new <b>invisible</b> package</b> product.</li>
              <li>Create <b>another SKU(s)</b> (Jet Peel) under this package.</li>
              <li>set its price to $0. as they are included in the plan.</li>
              <li>set its quota rule to Step 1's SOLO SKU quota rule.</li>
              <li>User's first purchase must pay the base Menopause Plan price at $3988, then,</li>
              <li>On-going topups of another SKU will use SOLO SKU's normal price $668, instead of $0.</li>
              <li>Additionally, attach <b>"OptionGroup"</b> to make the selection more complicated with added text and grouping (AXA in the same package, Breast+Ovary group by core, while others grouped by optional.)</li>
            </ul>
            <ul>
              <h4 class="text-center">
                -- Step 4 Further --
              </h4>
              <li>Repeat Step 1 & 3 until you create all the SKUs for the Package.</li>
              <li>Put <b>SOLO SKU</b> inside selection<b>product_latest_axa</b></li>
              <li>Put <b>PACKAGE SKU</b> inside selection<b>product_featured_axa</b></li>
              <li>You just need to manage time slots from the origin SKU.</li>
            </ul>
            <ul>
              <h4 class="text-center">
                -- Questions --
              </h4>
              <li>If you have any questions, See dev admin for example.</li>
            </ul>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <package-aside
      v-if="aside && asideMode === 'package'"
      :active="true"
      :product-id="product.id"
      :resource="packageResource"
      @changed="sku => { closeAside(false); $emit('updated') }"
      @aside="bol => { closeAside(bol); !bol && $emit('updated') }"
    />

    <package-mapping-aside
      v-if="aside && asideMode === 'mapping'"
      :active="true"
      :package="packageResource"
      :resource="mappingResource"
      :skus="product.skus"
      @changed="sku => { closeAside(false); $emit('updated') }"
      @aside="bol => { closeAside(bol); !bol && $emit('updated') }"
    />

    <option-group-aside
      v-if="aside && asideMode === 'option_group'"
      :active="true"
      :resource="optionGroupResource"
      :skus="product.skus"
      @changed="sku => { closeAside(false); $emit('updated') }"
      @aside="bol => { closeAside(bol); !bol && $emit('updated') }"
    />
    <!-- :skus="product.skus.filter(s => !inPackageSkuIds.includes(s.id))" -->
  </div>
</template>

<script>
import { MainMedia } from '@/components'
import OptionGroupAside from '@/modules/common/views/option-group/OptionGroupAside.vue'
import useProduct from '@/modules/product/views/useProduct'
import {
mdiClose,
mdiDeleteOutline,
mdiDotsVertical,
mdiPencil,
mdiPlus,
mdiStar,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import PackageAside from '../../package-resource/PackageAside.vue'
import PackageMappingAside from '../../package-resource/PackageMappingAside.vue'

export default {
  components: {
    PackageAside, PackageMappingAside, OptionGroupAside, MainMedia,
  },
  props: {
    product: { type: Object, required: true },
  },

  setup(props) {
    const tableColumnHeaders = [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Sort', value: 'sort', sortable: true },
      { text: 'Available SKUs', value: 'mapping_count', sortable: true },
      { text: 'Picks', value: 'min_pick', sortable: true },
      { text: 'Weight', value: 'min_weight', sortable: true },
      { text: 'ACTION', value: 'action', sortable: false },
    ]

    //     sort
    // mapping_count
    // min_pick
    const { confirmTrashPackage, confirmTrashPackageMapping } = useProduct()

    const aside = ref(false)
    const asideMode = ref('package')
    const packageResource = ref(false)
    const mappingResource = ref(false)
    const optionGroupResource = ref(false)

    const inPackageSkuIds = computed(() => props.product?.packages.map(pkg => pkg.mappings).flat().map(m => m.sku.id) || [])

    const closeAside = bool => {
      if (!bool) {
        aside.value = null
        packageResource.value = false
        mappingResource.value = false
      }
    }

    const setPackage = pkg => {
      packageResource.value = pkg
      mappingResource.value = false
      asideMode.value = 'package'
      aside.value = true
    }

    const setMapping = (pkg, mapping) => {
      packageResource.value = pkg
      mappingResource.value = mapping
      asideMode.value = 'mapping'
      aside.value = true
    }

    const setOptionGroup = optionGroup => {
      optionGroupResource.value = optionGroup
      asideMode.value = 'option_group'
      aside.value = true
    }

    const setPackageMappingCreate = pkg => {
      packageResource.value = pkg
      mappingResource.value = false
      asideMode.value = 'mapping'
      aside.value = true
    }

    return {
      tableColumnHeaders,
      confirmTrashPackage,
      confirmTrashPackageMapping,

      aside,
      asideMode,
      packageResource,
      mappingResource,
      optionGroupResource,
      closeAside,
      setPackage,
      setMapping,
      setPackageMappingCreate,
      setOptionGroup,

      inPackageSkuIds,

      icons: {
        mdiDotsVertical,
        mdiPlus,
        mdiClose,
        mdiPencil,
        mdiDeleteOutline,
        mdiStar,
      },
    }
  },
}
</script>

  <style scoped>
  .img-thumbnail {
    max-width: 50px;
    display: block;
  }
  </style>
