import { useConfirm, useNotifyErrors, useNotifySuccess, useStripTags, useTableOptions } from '@/composables'
import { t } from '@/plugins/i18n'
import { destroyDistributorMessage, fetchDistributorMessages } from '@api/distributor/message'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useList() {
  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Dist', value: 'distributor_id' },
    { text: 'Position', value: 'position' },
    { text: 'Content', value: 'content' },
    { text: 'FROM', value: 'started_at' },
    { text: 'TO', value: 'expires_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || '')
  const tableTotal = ref(0)
  const loading = ref(false)
  const tableSelectedData = ref([])

  const distributorMessageOptions = computed(() =>
    tableList.value.map(d => ({
      label: useStripTags(t(d.content)).substring(0, 150),
      value: d.id,
    })),
  )

  const loadDistributorMessages = (query = {}) =>
    fetchDistributorMessages(
      useTableOptions(options.value, {
        search: searchQuery.value,
        email: emailQuery.value,
        ...query,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        tableList.value = records
        tableTotal.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const deleteDistributorMessage = async message => {
    await useConfirm({
      title: 'Confirm Delete Message?',
      content: 'You cannot undo and all related resources will lose relation from it.',
    })
    destroyDistributorMessage(message.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadDistributorMessages()
      })
      .catch(useNotifyErrors)
  }

  const cachable = [options, searchQuery, emailQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      tableSelectedData.value = []
      loadDistributorMessages()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    options,
    tableList,
    tableTotal,
    loading,
    tableSelectedData,
    loadDistributorMessages,
    distributorMessageOptions,
    deleteDistributorMessage,
  }
}
