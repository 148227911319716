<template>
  <div>
    <seo-aside
      v-if="aside === 'seo'"
      :active="true"
      :resource="seoResource"
      instance-type="products"
      :instance-id="product.id"
      @changed="$emit('updated')"
    />
    <v-card class="mt-2">
      <v-card-title class="d-flex justify-space-between">
        SEO
        <a @click="setSeo()">Edit</a>
      </v-card-title>
      <v-card-text>
        <div
          v-if="product.seo"
        >
          <div><b>Short Description:</b> {{ t(product.seo.description) }}</div>
          <div><b>Keywords:</b> {{ t(product.seo.keywords) }}</div>
          <div><b>Slug</b> (Same as Product Slug) : {{ product.slug }}</div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SeoAside from '@/modules/common/views/seo-resource/SeoAside.vue'
import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

export default {
  components: { SeoAside },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const aside = ref('')
    const seoResource = ref(null)

    const setSeo = () => {
      aside.value = 'seo'
      seoResource.value = props.product.seo
    }

    return {
      aside,
      seoResource,
      setSeo,
      t,
    }
  },
}
</script>
