import axios from '@axios'

const fetchDistributorMessages = queryParams => axios.get('/admin/distributor-messages', { params: queryParams })

const storeDistributorMessage = data => axios.post('/admin/distributor-messages', data)

const updateDistributorMessage = (id, data) => axios.put(`/admin/distributor-messages/${id}`, data)

const destroyDistributorMessage = id => axios.delete(`/admin/distributor-messages/${id}`)

const attachDistributorMessage = (id, path, data) => axios.post(`/admin/distributor-messages/${id}/${path}/attach`, data)

const detachDistributorMessage = (id, path, data) => axios.post(`/admin/distributor-messages/${id}/${path}/detach`, data)

export {
  attachDistributorMessage,
  destroyDistributorMessage,
  detachDistributorMessage,
  fetchDistributorMessages,
  storeDistributorMessage,
  updateDistributorMessage,
}
