<template>
  <div>
    <v-card class="mb-7">
      <v-card-title>
        <div>
          <span>SKU List</span>
          <v-chip
            v-if="product.has_package"
            class="ml-2"
          >
            Package
          </v-chip>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="$router.push({
            name: 'sku-create-resource',
            query: { product_id: product.id }
          })"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add an SKU</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="product.skus"
        :items-per-page="99"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <main-media
              :media="product.media"
              :sku-id="item.id"
              class="img img-thumbnail mr-2"
            />

            <div>
              <div class="mb-2 mt-2">
                <b>{{ item.name.en }}</b>
              </div>

              <router-link
                v-if="item.quota_rule && item.quota_rule.rechargeable"
                :to="{ name: 'product-view', query: $route.query, params: { id: item.quota_rule.rechargeable.product_id } }"
              >
                <span v-if="item.id === item.quota_rule.rechargeable.id">(self)</span>
                <span v-else>(topup)</span>
              </router-link>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            :color="item.status === 'published' && item.active ? 'success' : 'secondary'"
          >
            {{ item.status === 'published' ? '' : item.status }}
            {{ item.active ? 'active' : 'disabled' }}
          </v-chip>
        </template>

        <!-- stock -->
        <template #[`item.stock`]="{item}">
          {{ item.stock }}
        </template>

        <!-- quota_rule_id -->
        <template
          #[`item.quota_rule_id`]="{item}"
        >
          <div v-if="item.quota_rule">
            {{ item.quota_rule.quota }}
          </div>
        </template>

        <!-- activity_rule_id -->
        <template
          #[`item.activity_rule_id`]="{item}"
        >
          <div v-if="item.activity_rule">
            {{ item.activity_rule.max_participant }}
          </div>
        </template>

        <!-- stock -->
        <template #[`item.language`]="{item}">
          <v-chip
            v-for="lang in item.languages"
            :key="lang.id"
            x-small
          >
            {{ lang.name.en.substr(0, 3) }}
          </v-chip>
        </template>

        <!-- <template #[`item.branch_id`]="{item}">
          <span v-if="item.location">
            {{ item.location.name.en }}
          </span>
        </template> -->

        <!-- <template #[`item.location_id`]="{item}">
          <v-chip
            v-if="item.location"
            class="ml-s"
            x-small
          >
            {{ item.location.name.en }}
            <span v-if="item.branches.length">[MAIN]</span>
          </v-chip>
        </template> -->

        <!-- stock -->
        <template #[`item.branch`]="{item}">
          <v-chip
            v-if="item.location"
            :key="item.location.uuid"
            class="ml-s"
            x-small
          >
            [MAIN] {{ item.location.name.en }}
          </v-chip>

          <v-chip
            v-for="branch in item.branches"
            :key="branch.uuid"
            class="ml-2"
            x-small
          >
            {{ branch.name.en }}
          </v-chip>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="asideSku = item">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status !== 'published'"
                color="success"
                @click="publishSku(item.id).then(() => $emit('updated'))"
              >
                <v-list-item-title>
                  <span>Publish</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'published'"
                color="error"
                @click="unpublishSku(item.id).then(() => $emit('updated'))"
              >
                <v-list-item-title>
                  <span>Unpublish</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'draft'"
                link
                @click="confirmTrashSku(item).then(() => $emit('updated'))"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Trash</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <sku-update-aside
      v-if="asideSku"
      :active="true"
      :sku-id="asideSku.id"
      :product="product"
      @changed="sku => { closeAside(false); $emit('updated') }"
      @aside="bol => { closeAside(bol); !bol && $emit('updated') }"
    />
  </div>
</template>

<script>
import { MainMedia } from '@/components'
import useProduct from '@/modules/product/views/useProduct'
import { t } from '@/plugins/i18n'
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiPencil,
  mdiPlus,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import SkuUpdateAside from '../../sku-resource/SkuUpdateAside.vue'

export default {
  components: { SkuUpdateAside, MainMedia },
  props: {
    product: { type: Object, required: true },
  },

  setup(props) {
    const tableColumnHeaders = computed(() => {
      const headers = [
        { text: 'NAME', value: 'name', sortable: false },
        { text: 'SKU NUMBER', value: 'sku_number' },
        { text: 'STATUS', value: 'status' },
        { text: 'STOCK', value: 'stock' },
        { text: 'LANGUAGES', value: 'language', sortable: false },
        { text: 'Branches', value: 'branch', sortable: false },
      ]

      if (['topup'].includes(props.product.nature)) {
        headers.push({ text: 'Quota', value: 'quota_rule_id', sortable: false })
      }

      if (['activity'].includes(props.product.nature)) {
        headers.push({ text: 'Activity', value: 'activity_rule_id', sortable: false })
      }

      if (['voucher', 'service', 'topup', 'activity'].includes(props.product.nature)) {
        headers.push({ text: 'LANGUAGES', value: 'language', sortable: false })
      }

      headers.push({ text: 'ACTION', value: 'action', sortable: false })

      return headers
    })

    const { publishSku, unpublishSku, confirmTrashSku } = useProduct()

    const asideSku = ref(null)
    const closeAside = bool => {
      if (!bool) {
        asideSku.value = null
      }
    }

    return {
      tableColumnHeaders,
      publishSku,
      unpublishSku,
      confirmTrashSku,

      asideSku,
      closeAside,

      icons: {
        mdiDotsVertical,
        mdiPlus,
        mdiPencil,
        mdiDeleteOutline,
      },

      t,
    }
  },
}
</script>

<style scoped>
.img-thumbnail {
  max-width: 50px;
  display: block;
}
</style>
