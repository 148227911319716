<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <attach-message-form
      :loading="loading"
      :errors="errors"
      :position="position"
      :ignore-message-ids="ignoreMessageIds"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { attachDistributorMessage } from '@api/distributor/message'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AttachMessageForm from './AttachMessageForm.vue'

export default {
  components: { AttachMessageForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    position: {
      type: String,
      default: 'product',
    },
    path: {
      type: String,
      default: 'products',
    },
    attachIds: { // product ids to attach
      type: Array,
      default: () => [],
    },
    ignoreMessageIds: { // message ids to hide
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const promises = []
      console.log('form is', form)
      form.message_ids.forEach(id => {
        promises.push(attachDistributorMessage(id, props.path, { attach_ids: props.attachIds })
          .catch(err => {
            errors.value = useExtractErrors(err)
          }))
      })

      await Promise.all(promises).then(() => {
        emit('changed')
        console.log('changed')
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
