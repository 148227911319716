<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <v-text-field
              v-model="form.stock"
              label="Stock"
              suffix="ppl"
              :rules="[between(form.stock, 0, 99999999)]"
            ></v-text-field>

            <v-select
              v-model="form.price_group_id"
              class="mt-2"
              label="Price Group"
              :items="priceGroupOptions"
              clearable
              item-text="title"
              item-value="value"
              outlined
            ></v-select>

            <h3 class="mt-2">
              From
            </h3>

            <date-time-picker v-model="form.started_at" />

            <v-divider class="mt-2"></v-divider>

            <h3 class="mt-2">
              To
            </h3>

            <date-time-picker v-model="form.ended_at" />

            <v-divider class="mt-2"></v-divider>

            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
              :message="[ 'Active rule will be applied to generate slots automatically.' ]"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { DateTimePicker } from '@/components'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import usePriceForm from '../price-resource/usePriceForm'

export default {
  components: { DateTimePicker },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    sku: {
      // default resource
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      stock: props.resource ? props.resource.stock : 100,
      price_group_id: props.resource ? props.resource.price_group_id : null,
      started_at: props.resource ? props.resource.started_at : null,
      ended_at: props.resource ? props.resource.ended_at : null,
      active: props.resource ? props.resource.active : true,
    }
    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        console.log('slot form', form.value)
        emit('submit', form.value)
      }
    }

    const { priceGroups, loadPriceGroups } = usePriceForm()

    onMounted(() => {
      loadPriceGroups(props.sku)
    })

    const priceGroupOptions = computed(() => priceGroups.value.map(group => ({ title: group.name.en, value: group.id })))

    return {
      form,
      formElem,
      validate,
      between,
      required,

      priceGroupOptions,
    }
  },
}
</script>
