import {
  fetchVouchers, storeVouchersFromGroup, updateVoucher
} from '@api/product'
import { ref } from '@vue/composition-api'

export default function useVoucherForm() {
  const voucherErrors = ref([])
  const loading = ref(false)
  const voucherData = ref(null)
  const vouchers = ref([]) // of a sku

  /**
   * With Tangible, we store voucher group first,
   * then store voucher inside the group.
   * group name is irrelevant.
   */

  const updateVoucherForm = (voucherId, form) => {
    loading.value = true

    return updateVoucher(voucherId, { ...form })
      .then(res => {
        voucherData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      }).finally(() => {
        loading.value = false
      })
  }

  const storeVouchersFromGroupForm = form => {
    loading.value = true

    return storeVouchersFromGroup({ ...form })
      .then(res => {
        voucherData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      }).finally(() => {
        loading.value = false
      })
  }

  const loadVouchers = (sku, queryParams) => fetchVouchers({ sku_id: sku.id, ...queryParams })
    .then(res => {
      const { records, pagination } = res.data.data
      vouchers.value = records

      // remove loading state

      return res
    })
    .catch(error => {
      console.log(error)

      return Promise.reject(error)
    }).finally(() => {
      loading.value = false
    })

  return {
    loading,
    voucherErrors,
    voucherData,
    vouchers,

    updateVoucherForm,
    storeVouchersFromGroupForm,
    loadVouchers,
  }
}
