var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'price')?_c('price-aside',{attrs:{"active":true,"price-group":_vm.priceGroupData,"resource":_vm.priceData,"product":_vm.product},on:{"changed":_vm.refresh,"aside":_vm.closeAside}}):_vm._e(),(_vm.priceData && _vm.aside === 'channel')?_c('channel-price-aside-update',{attrs:{"active":true,"sku":_vm.sku,"price":_vm.priceData},on:{"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),(_vm.aside === 'price_group')?_c('price-group-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.priceGroupData},on:{"updated":_vm.refresh,"created":_vm.createdPriceGroup,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.sku.name.en))]),_c('v-spacer'),(['service', 'activity'].includes(_vm.product.nature) || _vm.priceGroups.length === 0)?_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleGroupAside(null, 'price_group')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Group")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.priceGroups,"items-per-page":99,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" - "+_vm._s(item.name.en)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[(price.main && ['service', 'activity', 'plan'].includes(_vm.product.nature))?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiStar)+" ")]):_vm._e(),_vm._v(" "+_vm._s(price.name.en)+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)})}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[(price.computed_cost)?_c('span',[_vm._v(_vm._s(_vm._f("localPrice")(price.computed_cost,{ currency: _vm.currency })))]):_vm._e(),(price.msr_price)?_c('span',{staticClass:"ml-1"},[_vm._v("("+_vm._s(_vm._f("localPrice")(price.msr_price,{ currency: _vm.currency }))+")")]):_c('span',{staticClass:"ml-1"},[_vm._v("(na)")]),(price.cost_type === 'percentage')?_c('span',{staticClass:"ml-1"},[_vm._v("("+_vm._s(price.cost_value)+"%)")]):_vm._e(),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[_vm._v(" "+_vm._s(_vm._f("localPrice")(price.selling_price,{ currency: _vm.currency }))+" "),(parseInt(price.list_price))?_c('span',{staticClass:"ml-2 cross-out"},[_vm._v(_vm._s(price.list_price))]):_vm._e(),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),(_vm.product.tax_scheme)?_c('div',{staticClass:"tax-amount"},[_vm._v(" "+_vm._s(_vm.t(_vm.product.tax_scheme.name))+" - "),_c('b',[_vm._v(_vm._s(_vm.getTaxAmount(price.selling_price, _vm.product.tax_scheme.percentage)))]),_vm._v(" ("+_vm._s(_vm.product.tax_scheme.percentage)+"% included) ")]):_vm._e()],1)})}},{key:"item.min_quantity",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[_vm._v(" "+_vm._s(price.min_quantity)+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),(item.prices.find(function (p) { return p.min_quantity !== 1 && p.main; }))?_c('p',{staticClass:"text--error"},[_vm._v(" Err: Please make sure the main price is min_quantity=1 ")]):_vm._e()],1)})}},{key:"item.ch_price",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toggleChannelAside(price)}}},[_vm._v(" "+_vm._s(_vm.channelPriceRange(price))+" "),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleGroupAside(item, 'price_group')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update Group")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.togglePriceAside(null, item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Add New Price")])],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete "+_vm._s(item.name.en))])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }