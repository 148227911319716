<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <package-mapping-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :sku-options="skus.map(s => ({
        title: `${s.name.en}`,
        value: s.id,
      }))"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storePackageMapping, updatePackageMapping } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PackageMappingForm from './PackageMappingForm.vue'

export default {
  components: {
    PackageMappingForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    package: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
    skus: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      // console.log('form', form)
      loading.value = true
      const request = props.resource ? updatePackageMapping(props.resource.id, {
        ...form,
      }) : storePackageMapping({
        package_id: props.package.id,
        ...form,
      })
      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
